<script setup lang="ts">
// icons
import { faImages } from '@fortawesome/pro-solid-svg-icons';

// components
import CiMapMarker from '@/components/map/CiMapMarker/CiMapMarker.vue';

/********************
 * REFS & VARS       *
 ********************/
export interface CiCampsiteDetailHeroProps {
  campsite: any;
  campsiteMapData: any;
}
const props = withDefaults(defineProps<CiCampsiteDetailHeroProps>(), {});

const emit = defineEmits<{
  'on-map-clicked': [];
}>();

/********************
 * COMPOSITIONS      *
 ********************/
const localePath = useLocalePath();
const { $gettext } = useGettext();
const { fixImageUrlAndCheckWebp } = useWebp();
const { getMapTilesUrl } = useMapSettings();
const { locale } = useI18n();

/********************
 * REFS & VARS       *
 ********************/
const imageJpg = ref(null);
const imageWebp = ref(null);

const hasImages = computed(() => {
  return !!props.campsite.media.images.length;
});

// const imageCount = computed(() => {
//   return (props.campsite.media_count.pictures.campsite || 0) + (props.campsite.media_count.pictures.visitor || 0);
// });

const pictures = computed(() => {
  if (!hasImages.value) {
    return [];
  }

  const pics = props.campsite.media.images.map((img) => {
    const jpg = fixImageUrlAndCheckWebp(img.url);
    const webp = fixImageUrlAndCheckWebp(img.url, true);
    return [
      {
        url: webp.url,
        srcset: `${webp.url} ${img.width}w`,
        media: '(min-width: 0px)',
        type: webp.contentType,
        width: img.width,
        height: img.height,
      },
      {
        url: jpg.url,
        srcset: `${jpg.url} ${img.width}w`,
        media: '(min-width: 0px)',
        type: jpg.contentType,
        width: img.width,
        height: img.height,
      },
    ];
  });
  return pics;
});

const pictureLink = computed(() => {
  return localePath({
    name: 'campsite-identifier-media',
    params: { identifier: props.campsite.slug },
  });
});

if (props.campsite?.meta?.geo_position?.coordinates[0] && props.campsite?.meta?.geo_position?.coordinates[1]) {
  const longitude = `${props.campsite.meta.geo_position.coordinates[0]}`;
  const latitude = `${props.campsite.meta.geo_position.coordinates[1]}`;

  imageJpg.value = getMapTilesUrl(locale.value, 'STATIC')
    .replace('{lat}', latitude)
    .replace('{lng}', longitude)
    .replace('{zoom}', 14)
    .replace('{width}', 1000)
    .replace('{height}', 410);

  // this should be save based on the defined MAP_STATIC_URL in mapSettings.js
  imageWebp.value = imageJpg.value?.replace(/.jpg/g, '.webp');
}
</script>

<template>
  <section class="container relative grid grid-cols-2 gap-[10px]">
    <template v-if="hasImages">
      <div class="col-span-2 lg:col-span-1">
        <div class="relative overflow-hidden rounded bg-gray-light pt-[75%]">
          <nuxt-link
            v-if="campsite.media.images.length"
            class="absolute left-0 top-0 block h-auto w-full"
            :to="pictureLink"
          >
            <picture>
              <source
                v-for="(source, idx) of pictures[0]"
                :key="idx"
                :srcset="source.srcset"
                :media="source.media"
                :type="source.type"
              >

              <img
                :src="pictures[0][1].url"
                :width="pictures[0][1].width"
                :height="pictures[0][1].height"
                class="h-auto w-full"
                loading="eager"
                fetchpriority="high"
                decoding="async"
                alt=""
                data-cy="ci-campsite-detail-hero--hero-picture"
              >
            </picture>
          </nuxt-link>
        </div>
      </div>

      <div class="col-span-2 hidden lg:col-span-1 lg:block">
        <div class="relative overflow-hidden rounded bg-gray-light pt-[75%]">
          <nuxt-link
            v-if="campsite.media.images.length > 1"
            class="absolute left-0 top-0 block h-auto w-full"
            :to="pictureLink"
            event
          >
            <picture>
              <source
                v-for="(source, idx) of pictures[1]"
                :key="idx"
                :srcset="source.srcset"
                :media="source.media"
                :type="source.type"
              >

              <img
                :src="pictures[1][1].url"
                :width="pictures[1][1].width"
                :height="pictures[1][1].height"
                class="h-auto w-full"
                loading="eager"
                fetchpriority="high"
                decoding="async"
                alt=""
              >
            </picture>
          </nuxt-link>
          <div
            v-else
            class="cursor-pointer"
            @click="emit('on-map-clicked')"
          >
            <picture>
              <source
                :srcset="imageWebp"
                type="image/webp"
              >
              <source
                :srcset="imageJpg"
                type="image/jpg"
              >
              <img
                class="absolute left-0 top-0 size-full object-cover"
                :src="imageJpg"
                loading="eager"
                alt="map"
              >
            </picture>
            <CiMapMarker
              :campsite="campsiteMapData"
              class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-full"
            />
          </div>
        </div>
      </div>

      <div
        v-if="hasImages"
        class="absolute right-[30px] top-[15px] flex flex-row"
      >
        <nuxt-link
          v-if="hasImages"
          :to="pictureLink"
          class="button button--primary-outline-white mr-0 md:mr-4"
        >
          <CiAwesomeIcon
            :icon="faImages"
            ratio="0.7"
          />
          <span class="text-nowrap">{{ $gettext('Show all') }}</span>
        </nuxt-link>
      </div>
    </template>

    <!-- map fallback -->
    <div
      v-if="!hasImages && imageJpg"
      class="col-span-2"
    >
      <div
        class="relative cursor-pointer overflow-hidden rounded pt-[75%] lg:pt-[37.1633%]"
        @click="emit('on-map-clicked')"
      >
        <div
          :style="`background-image: url(${imageJpg})`"
          class="content-[' '] absolute left-0 top-0 size-full bg-cover bg-center bg-no-repeat"
        />
        <CiMapMarker
          :campsite="campsiteMapData"
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-full"
        />
      </div>
    </div>
  </section>
</template>

<style></style>
