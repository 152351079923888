<script setup lang="ts">
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiFanclubDetailsProps {
  items: any;
}
const props = withDefaults(defineProps<CiFanclubDetailsProps>(), {});

/********************
 * COMPOSITIONS      *
 ********************/
const localePath = useLocalePath();
const { $gettext } = useGettext();

/********************
 * REFS & VARS       *
 ********************/
const i18nHeadline = computed(() => {
  return $gettext('Your <strong class="uppercase">Fanclub</strong> Benefits', true);
});
</script>

<template>
  <div class="mt-6 rounded-[8px] bg-dark-green text-white md:flex">
    <div class="p-6">
      <img
        src="~/assets/svg/fanclub-logo.svg"
        loading="lazy"
        width="130"
        height="130"
        alt="Fanclub logo"
        class="m-auto mt-[-50px] block"
      >
    </div>
    <div class="px-4 pb-4 pt-2 md:pl-2 md:pt-6">
      <h3
        class="mb-0"
        v-html="i18nHeadline"
      />
      <h3>{{ $pgettext('Your Fanclub benefits "on site"', 'on site') }}</h3>
      <ul class="list-['✓'] p-0 pl-3.5 pt-4">
        <li
          v-for="(item, idx) of props.items"
          :key="idx"
          class="px-2"
        >
          {{ item }}
        </li>
      </ul>
      <div class="mt-6">
        <div class="mb-2">
          {{ $gettext('Not a FANCLUB member, yet?') }}
        </div>
        <nuxt-link
          :to="localePath({ name: 'info-all', params: { all: 'fanclub' } })"
          class="button button--primary-outline-white mx-auto"
        >
          <span>{{ $gettext('Find out more about the FANCLUB now') }}</span>
          <CiAwesomeIcon
            :icon="faChevronRight"
            ratio="0.6"
          />
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<style></style>
