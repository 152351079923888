<script setup lang="ts">
import CiSkeletonItem from '@/components/skeleton/CiSkeletonItem.vue';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiSkeletonCampsiteDetailProps {
  hasAds?: boolean;
  hasTopLink?: boolean;
}
const props = withDefaults(defineProps<CiSkeletonCampsiteDetailProps>(), {
  hasAds: false,
  hasTopLink: false,
});
</script>

<template>
  <div
    class="relative"
    :class="{ 'md:pt-6 lg:pt-12': !props.hasTopLink }"
  >
    <template v-if="props.hasAds">
      <div class="absolute size-full">
        <div class="container h-full">
          <div class="flex h-full">
            <CiSkeletonItem
              class="ad ad--skyscraper ad--skyscraper-left sticky-top z-10"
              style="top: 50px"
            />
            <CiSkeletonItem
              class="ad ad--skyscraper ad--skyscraper-right sticky-top z-10"
              style="top: 50px"
            />
          </div>
        </div>
      </div>

      <div class="container-lg mb-4 pt-0">
        <CiSkeletonItem class="ad ad--header" />
      </div>
    </template>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="relative mb-6 mt-0">
            <div
              v-if="props.hasTopLink"
              class="row mt-1"
            >
              <div class="col-12">
                <CiSkeletonItem class="mb-2 h-[50px] w-[200px] max-w-[80%]" />
              </div>
            </div>

            <div class="row row-small-gutter">
              <div class="col-12 col-lg-6">
                <CiSkeletonItem class="h-auto w-full rounded pt-[75%]" />
              </div>
              <div class="col-12 col-lg-6 hidden lg:block">
                <CiSkeletonItem class="h-auto w-full rounded pt-[75%]" />
              </div>
            </div>

            <div class="row mt-12">
              <div class="col-12">
                <CiSkeletonItem class="mb-3 h-[30px] w-1/2 md:mx-auto md:mt-0" />
              </div>
              <div class="col-12">
                <CiSkeletonItem class="h-[50px] w-[70%] md:mx-auto md:my-0" />
              </div>

              <div class="col-12 mb-6 mt-4 md:mb-0">
                <CiSkeletonItem class="h-[100px] w-[200px] md:mx-auto md:my-0" />
              </div>
            </div>

            <div class="row mt-12">
              <div class="col-12 col-lg-8 offset-lg-2">
                <CiSkeletonItem class="h-[75px] rounded md:h-[52px]" />
              </div>
            </div>

            <div class="row mt-6">
              <div class="col-12 col-lg-6 offset-lg-3">
                <CiSkeletonItem class="h-10 rounded" />
              </div>
            </div>

            <div class="row my-6">
              <div class="col-12 col-md-8 offset-md-2">
                <hr class="h-px border-0 bg-gray-40">
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-6 offset-lg-3">
                <CiSkeletonItem class="h-[200px] rounded" />
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <CiSkeletonItem class="my-6 h-[500px]" />
                <CiSkeletonItem class="my-6 h-[500px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
