<script setup lang="ts">
// icons
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

// components
import CiContactModal from '@/components/CiModal/CiContactModal.vue';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiCampsiteSectionContactProps {
  campsite: any;
  hasAvailabilitySection?: boolean;
  zone: string;
}
const props = withDefaults(defineProps<CiCampsiteSectionContactProps>(), {
  hasAvailabilitySection: false,
});

const emit = defineEmits<{
  'check-availability': [];
}>();

/********************
 * COMPOSITIONS      *
 ********************/
const appStore = useAppStore();
const backendStore = useBackendStore();
const getRouteBaseName = useRouteBaseName();
const localePath = useLocalePath();
const route = useRoute();
const { $gettext } = useGettext();
const { locale } = useI18n();
const { openModal, closeModal } = useCiModal();
const { ppcWebsiteLink } = useCampsiteDetail({
  campsite: () => props.campsite,
});

/********************
 * REFS & VARS       *
 ********************/
const websiteLink = computed(() => {
  return `${backendStore.url}/external-link/?lang=${locale.value}&slug=${props.campsite.slug}&link_identifier=campsite-detail-bottom`;
});

const websiteName = computed(() => {
  return new URL(props.campsite.meta?.website)?.host;
});

const showCampingId = computed(() => {
  return import.meta.client;
});

/********************
 * FUNCTIONS         *
 ********************/
function showContact() {
  appStore.ti({
    slug: props.campsite.slug,
    lang: locale.value,
    ti: 'modal-phone-fax',
    te: `${getRouteBaseName(route)}-${props.zone}`,
  });

  openModal(
    {
      component: CiContactModal,
      attrs: {
        contactData: props.campsite.meta,
        onClose: () => {
          closeModal('CiContactModal');
        },
      },
    },
    {
      clickToClose: true,
      contentTransition: 'translate-y-down',
      contentClass: 'max-w-[600px] w-full h-auto',
    },
  );
}
</script>

<template>
  <section>
    <h2
      key="section-headline-contact"
      class="px-6 text-center text-black"
    >
      {{ $gettext('Contact') }}
    </h2>

    <div class="container mt-12">
      <div class="row">
        <div class="col-12 text-center">
          <h3>{{ props.campsite.meta.name }}</h3>
          <client-only>
            <div
              v-if="showCampingId"
              class="text-small-book font-weight-light text-gray-40"
            >
              Camping-Id: {{ props.campsite.camping_id }}
            </div>
          </client-only>
        </div>
      </div>

      <div class="row mt-6">
        <div class="col-12">
          <div class="flex flex-col md:flex-row">
            <client-only>
              <div
                v-if="props.hasAvailabilitySection || props.campsite.meta.inquiries_allowed"
                class="mb-12 flex min-w-0 shrink grow basis-0 flex-col items-center md:mb-0"
              >
                <img
                  loading="lazy"
                  :src="`/assets/img/icon_planning.svg`"
                  alt="Vacation planning"
                  class="mx-auto"
                  width="115"
                  height="115"
                  style="width: 115px; max-width: 100%"
                >
                <div class="my-4">
                  {{ $gettext('Vacation planning') }}
                </div>
                <button
                  v-if="props.hasAvailabilitySection"
                  class="button button--primary"
                  type="button"
                  @click="emit('check-availability')"
                >
                  <span>{{ $gettext('Check availability') }}</span>
                </button>
                <nuxt-link
                  v-else-if="!props.hasAvailabilitySection && props.campsite.meta.inquiries_allowed"
                  class="button button--primary"
                  :to="localePath({ name: 'campsite-identifier-inquire', params: route.params, query: route.query })"
                >
                  <span>{{ $gettext('Send inquiry') }}</span>
                </nuxt-link>
              </div>
            </client-only>
            <div
              v-if="props.campsite.meta.phone1 || props.campsite.meta.phone2 || props.campsite.meta.fax"
              class="my-12 flex min-w-0 shrink grow basis-0 flex-col items-center md:my-0"
            >
              <img
                loading="lazy"
                :src="`/assets/img/icon_phone.svg`"
                alt="call or fax"
                class="mx-auto"
                width="115"
                height="115"
                style="width: 115px; max-width: 100%"
              >
              <div class="my-4">
                {{ $gettext('Call or fax') }}
              </div>
              <button
                class="button button--primary-outline"
                type="button"
                @click="showContact"
              >
                {{ $gettext('Show number') }}
              </button>
            </div>
            <div
              v-if="campsite.meta.website"
              class="mt-12 flex min-w-0 shrink grow basis-0 flex-col items-center md:mt-0"
            >
              <img
                loading="lazy"
                :src="`/assets/img/icon_desktop.svg`"
                alt="website"
                class="mx-auto"
                width="115"
                height="115"
                style="width: 115px; max-width: 100%"
              >
              <a
                :href="websiteLink"
                class="my-4 truncate text-dark"
                referrerpolicy="unsafe-url"
                target="_blank"
                rel="noopener"
                style="max-width: 100%"
              >
                {{ websiteName }}
              </a>
              <a
                v-if="ppcWebsiteLink('bottom')"
                :href="ppcWebsiteLink('bottom') || undefined"
                class="button button--primary-outline min-w-0 shrink grow basis-0"
                referrerpolicy="unsafe-url"
                target="_blank"
                rel="noopener"
              >
                <span>{{ $gettext('Go to website') }}</span>
                <CiAwesomeIcon
                  :icon="faChevronRight"
                  ratio="0.6"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style></style>
