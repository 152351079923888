<script setup lang="ts">
// icons
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

// components
import CiCampsiteDetailRatings from '@/components/campsitedetail/CiCampsiteSectionRating/CiCampsiteDetailRatings/CiCampsiteDetailRatings.vue';
import CiReviewSlider from '@/components/CiReview/CiReviewSlider.vue';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiCampsiteSectionRatingProps {
  activities: any;
  campsite: any;
  hasAds?: boolean;
  ratingOverview: any;
  suggestedGuests: any;
}
const props = withDefaults(defineProps<CiCampsiteSectionRatingProps>(), {
  hasAds: false,
});

// const emit = defineEmits<{
//   'review-count': [value: number];
// }>();

/********************
 * COMPOSITIONS      *
 ********************/
const localePath = useLocalePath();
const { mapRating } = useRating();
const { $gettext, $ngettext } = useGettext();

/********************
 * REFS & VARS       *
 ********************/
const filteredRatings = computed(() => {
  return props.ratingOverview.reduce((filtered, option) => {
    if (props.campsite.rating_campsite[option.key]) {
      filtered.push({ ...option, value: props.campsite.rating_campsite[option.key] });
    }
    return filtered;
  }, []);
});
</script>

<template>
  <div>
    <h2 class="px-6 text-center text-black">
      {{ $gettext('Ratings') }}
    </h2>

    <div class="container">
      <div class="grid grid-cols-12 gap-x-[30px]">
        <!-- overall rating -->
        <div class="col-span-12 mt-12 md:col-span-6 md:col-start-4">
          <div class="row md:mt-auto">
            <div class="col-4 offset-4 mb-2">
              <CiRatingRadial
                :rating="props.campsite.rating_campsite.rating_avg_overall"
                class="chart-wrapper chart-wrapper--border-md mx-auto block w-full"
                font-class="text-2xl font-medium"
                style="max-width: 100px"
              />
            </div>
          </div>
          <div class="row md:mb-auto">
            <div
              v-if="props.campsite.rating_guest.rating_count"
              class="col-12 flex justify-center text-center font-medium uppercase"
              data-nosnippet
            >
              {{ mapRating(props.campsite.rating_campsite.rating_avg_overall) }}
            </div>
            <div class="col-12 flex justify-center" data-nosnippet>
              <span
                v-if="props.campsite.rating_guest.rating_count"
                class="text-small-book"
              >
                {{
                  $ngettext('%{count} rating', '%{count} ratings', props.campsite.rating_guest.rating_count, {
                    count: props.campsite.rating_guest.rating_count,
                  })
                }}
              </span>
              <span
                v-else
                class="text-small-book"
              >{{ $gettext('No ratings yet') }}</span>
            </div>
            <div class="col-12 mt-6 flex justify-center">
              <nuxt-link
                :to="localePath({ name: 'campsite-identifier-rate', params: { identifier: props.campsite.slug } })"
                class="button button--primary icon-absolute mb-2 w-full"
              >
                <span>{{ $gettext('Rate campsite') }}</span>
                <CiAwesomeIcon
                  :icon="faChevronRight"
                  ratio="0.6"
                />
              </nuxt-link>
            </div>
            <div class="col-12">
              <div class="row no-gutters">
                <div class="col-12 col-lg-6 pr-0 lg:!pr-2">
                  <nuxt-link
                    :to="
                      localePath({
                        name: RBN_VIDEO_UPLOAD,
                        params: {
                          identifier: props.campsite.slug,
                        },
                      })
                    "
                    class="button button--primary-outline icon-absolute mb-2 w-full lg:mb-0"
                  >
                    <span class="text-center">{{ $gettext('Add Video') }}</span>
                    <CiAwesomeIcon
                      :icon="faChevronRight"
                      ratio="0.6"
                    />
                  </nuxt-link>
                </div>
                <div class="col-12 col-lg-6 pr-0 lg:!pl-2">
                  <nuxt-link
                    :to="
                      localePath({
                        name: RBN_IMAGE_UPLOAD,
                        params: {
                          identifier: props.campsite.slug,
                        },
                      })
                    "
                    class="button button--primary-outline icon-absolute w-full"
                  >
                    <span class="text-center">{{ $gettext('Add Images') }}</span>
                    <CiAwesomeIcon
                      :icon="faChevronRight"
                      ratio="0.6"
                    />
                  </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- single ratings -->
        <div
          v-if="filteredRatings.length"
          v-once
          class="col-span-12 mt-12 pt-12"
        >
          <h3
            class="text-center"
            v-html="$gettext('<strong>Ratings</strong> in detail', true)"
          />
          <ul class="m-0 mt-4 grid list-none grid-cols-2 gap-x-[30px] p-0">
            <li
              v-for="(rating, index) in filteredRatings"
              :key="`rating_overview_${index}`"
              class="col-span-2 lg:col-span-1"
            >
              <div class="flex items-center">
                <div
                  :title="rating.translation"
                  class="grow truncate"
                >
                  {{ rating.translation }}
                </div>
                <div
                  class="mx-2 pr-0 text-right"
                  style="min-width: 30px"
                >
                  {{ rating.value && rating.value.toFixed(1) }}
                </div>
                <CiRatingRadial
                  style="width: 40px; max-width: 100%"
                  :rating="rating.value"
                  :show-label="false"
                  class="chart-wrapper chart-wrapper--border-none ml-auto mr-1 block shrink-0 p-1"
                />
              </div>
              <hr
                v-if="index !== props.ratingOverview.length - 1"
                class="my-4 h-px border-0 bg-gray-40"
              >
            </li>
          </ul>
        </div>
      </div>
      <div
        v-if="props.hasAds"
        :class="[!filteredRatings.length ? 'mt-12' : 'mt-6']"
      >
        <CiAdSlot
          id="camping_content_3"
          class="ad--h-250"
        />
      </div>
    </div>

    <!-- review slider -->
    <div class="overflow-x-hidden" data-nosnippet>
      <CiReviewSlider
        :campsite-slug="props.campsite.slug"
        :initial-has-reviews="!!props.campsite.rating_campsite.rating_avg_overall"
      />
    </div>

    <CiCampsiteDetailRatings
      :activities="props.activities"
      :suggested-guests="props.suggestedGuests"
      :has-ads="props.hasAds"
    />
  </div>
</template>

<style></style>
