<script setup lang="ts">
import { defineRule, useForm, useField } from 'vee-validate';
import { email, required, max, min } from '@vee-validate/rules';

// types
import type { UserProfile } from '@/types';

// rules
defineRule('email', email);
defineRule('max', max);
defineRule('min', min);
defineRule('required', required);

/********************
* COMPOSITIONS      *
********************/
const runtimeConfig = useRuntimeConfig();
const switchLocalePath = useSwitchLocalePath();
const { $gettext } = useGettext();

/********************
* PROPS & EMITS     *
********************/
export interface CiCampsiteDetailProposeChangeModalProps {
  user: UserProfile | null;
  campsite: any;
}
const props = withDefaults(defineProps<CiCampsiteDetailProposeChangeModalProps>(), {
});

const emit = defineEmits<{
  close: [];
}>();

/********************
* REFS & VARS       *
********************/
const status = ref<'idle' | 'success' | 'error' | 'loading'>('idle');

const { validate } = useForm({
  validationSchema: {
    description: 'required|max:2000',
    partner_email: 'required|email',
    partner_name: 'required',
  },
});

const { value: descriptionValue, errorMessage: descriptionError } = useField<string>('description');
const { value: emailValue, errorMessage: emailError } = useField<string>('partner_email');
const { value: nameValue, errorMessage: nameError } = useField<string>('partner_name');

if (props.user?.profile) {
  if (props.user.profile.first_name && props.user.profile.last_name) {
    nameValue.value = `${props.user.profile.first_name} ${props.user.profile.last_name}`;
  }

  if (props.user.profile.email) {
    emailValue.value = props.user.profile.email;
  }
}

const name = `[SUGGESTED CHANGE] Proposed change for ${props.campsite.meta?.name}, ${props.campsite.camping_id}`;

const formLoading = computed(() => {
  return status.value === 'loading';
});

async function submit() {
  status.value = 'loading';
  const { valid } = await validate();

  if (!valid) {
    status.value = 'idle';
    return false;
  }

  try {
    const response = await $fetch('/api/propose-change', {
      method: 'POST',
      body: JSON.stringify({
        campsite_frontend_url: `${runtimeConfig.public.baseUrl}${switchLocalePath('de')}`,
        campsite_id: props.campsite.id,
        campsite_name: props.campsite.meta?.name,
        description: descriptionValue.value,
        name: name,
        partner_email: emailValue.value,
        partner_name: nameValue.value,
      }),
    });

    if (response) {
      status.value = 'success';
    }
  } catch (error) {
    status.value = 'error';
  }
}
</script>

<template>
  <div
    class="flex h-full flex-col rounded-lg bg-white shadow-2xl"
  >
    <CiModalHeader
      :title="$gettext('Propose change')"
      :closeable="true"
      @modal-close="!formLoading && emit('close')"
    />

    <div class="p-4">
      <template v-if="status === 'idle' || formLoading">
        <p>
          {{ $gettext('Your suggestion will help us to improve camping.info. Thank you for your help.') }}
        </p>

        <form
          novalidate
          @submit.prevent="submit"
        >
          <div class="grid grid-cols-2 gap-x-[30px]">
            <InputTextfield
              id="partner_name"
              v-model="nameValue"
              class="col-span-2 md:col-span-1"
              :error="!!nameError"
              :label="$gettext('Name')"
              inverted
              :required="true"
              :disabled="formLoading"
              name="partner_name"
              type="text"
            />

            <InputTextfield
              id="partner_email"
              v-model="emailValue"
              class="col-span-2 md:col-span-1"
              :label="$gettext('E-Mail')"
              :error="!!emailError"
              type="email"
              inverted
              :disabled="formLoading"
              :required="true"
              name="partner_email"
            />
          </div>

          <InputTextfield
            id="description"
            v-model="descriptionValue"
            type="textarea"
            :error="!!descriptionError"
            :required="true"
            rows="10"
            :label="$gettext('Proposed amendment:')"
            :placeholder="$gettext('Enter your suggestion here...')"
            :maxlength="2000"
            :disabled="formLoading"
            inverted
            name="description"
          />

          <div class="mb-4 flex justify-between">
            <button
              class="button"
              type="button"
              :class="[formLoading ? 'button--disabled' : 'button--dark-outline']"
              :disabled="formLoading"
              @click="emit('close')"
            >
              {{ $gettext('Cancel') }}
            </button>
            <CiButton
              :class="[formLoading ? 'button--disabled' : 'button--primary']"
              :disabled="formLoading"
              class="button "
              @click="submit"
            >
              <span>{{ $gettext('Send') }}</span>
            </CiButton>
          </div>
        </form>
      </template>

      <template v-if="status === 'success'">
        <div class="my-4 flex flex-col items-center">
          <CiAnimatedCheck
            v-if="status === 'success'"
            class="animated-check mx-auto my-0 block max-w-28"
          />
          <p class="mt-6">
            {{ $gettext('Your suggestion has been successfully submitted') }}
          </p>
          <button
            class="button button--primary mt-4"
            type="button"
            @click="emit('close')"
          >
            {{ $gettext('Back to campsite') }}
          </button>
        </div>
      </template>

      <template v-if="status === 'error'">
        <div class="my-4 flex flex-col items-center">
          <CiAnimatedCross
            class="animated-cross mx-auto my-0 block max-w-28"
          />
          <p class="mt-6">
            {{ $gettext('An error occurred. Please try again later.') }}
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<style></style>
