<script setup lang="ts">
// icons
import { faMessageSmile } from '@fortawesome/pro-regular-svg-icons';

import CiCampsiteDetailProposeChangeModal from '@/components/campsitedetail/CiCampsiteDetailProposeChange/CiCampsiteDetailProposeChangeModal.vue';

/********************
 * COMPOSITIONS      *
 ********************/
const campsiteStore = useCampsiteStore();
const userStore = useUserStore();
const { $gettext } = useGettext();
const { openModal, closeModal } = useCiModal();
const { trackLinkClick } = useTracking();

/********************
 * REFS & VARS       *
 ********************/
const shareTrackingContext = computed(() => {
  return [
    {
      schema: 'iglu:com.camparound/campinginfo_campsite/jsonschema/1-0-1',
      data: {
        booked_products: campsiteStore.campsite?.purchases || null,
        camping_id: campsiteStore.campsite?.camping_id,
        civ2_id: campsiteStore.campsite?.id,
        slug: campsiteStore.campsite?.slug,
      },
    },
  ];
});

/********************
 * FUNCTIONS         *
 ********************/
function openFeedbackModal(event: MouseEvent) {
  const { classList, innerHTML, dataset } = event.currentTarget as HTMLAnchorElement;
  const id = dataset?.trackingId;

  trackLinkClick({
    targetUrl: 'modal-suggest-change',
    elementId: id,
    elementClasses: Array.from(classList),
    elementTarget: '_blank',
    elementContent: innerHTML,
    context: shareTrackingContext.value,
  });

  openModal(
    {
      component: CiCampsiteDetailProposeChangeModal,
      attrs: {
        user: userStore.user,
        campsite: campsiteStore.campsite,
        onClose: () => {
          closeModal('CiCampsiteDetailProposeChangeModal');
        },
      },
    },
    {
      clickToClose: true,
      contentTransition: 'translate-y-down',
      contentClass: 'max-w-[800px] w-full max-h-full',
    },
  );
}
</script>

<template>
  <a
    class="inline-flex items-center"
    href="#"
    @click.prevent="openFeedbackModal"
  >
    <CiAwesomeIcon
      :icon="faMessageSmile"
      class="mr-2"
      ratio="0.7"
    />
    <span>{{ $gettext('Propose change') }}</span>
  </a>
</template>

<style></style>
